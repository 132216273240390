@import url('../colors.css');

.spinner-container {
    display: flex;
    justify-content: center;
  }
  
  .loading-spinner {
    margin-top: 12px;
    border-top: 4px dashed var(--secondary-color);
    border-radius: 50%;
    width: 30px;
    height: 30px;
    animation: spin 2s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  