@import url('../colors.css');

.slip-example {
    text-align: left;
    margin: 20px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 10px;
}

.slip-example h3 {
    margin-bottom: 10px;
}

textarea {
    width: 90%;
    padding: 10px;
    margin: 20px auto;
    border-radius: 5px;
    border: 1px solid #ccc;
    resize: vertical;
}

button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    background-color: var(--secondary-color);
    color: white;
    cursor: pointer;
}

button:hover {
    background-color: #45a049;
}

.response-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
    margin-top: 20px;
}

.response-card {
    background-color: white;
    border: 1px solid #ddd;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 15px;
    width: 200px;
    /* Fixed width for desktop */
    text-align: left;
    margin: 0 auto;
    /* Centering the card */
}

.response-card p {
    margin: 2px;
    color: #333;
}

.success {
    border: 1px solid #4CAF50;
    background-color: #e8f5e9;
}

.failure {
    border: 1px solid #f44336;
    background-color: #ffebee;
}

/* Media query for small screens */
@media (max-width: 600px) {
    .App-header {
        min-height: 15vh;
        /* Adjust the header height on small screens */
    }

    .response-card {
        width: 100%;
        /* Full width on smaller screens */
    }

    textarea {
        width: 100%;
        /* Full width on smaller screens */
    }
}